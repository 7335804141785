@charset "utf-8";

@font-face {
    font-family: 'Hack';
    src: url('../font/Hack-Regular.ttf');
}

// @font-face {
//     font-family: 'Hack';
//     src: url('../font/Hack-Bold.ttf');
//     font-weight: bold;
// }

// @font-face {
//     font-family: 'Hack';
//     src: url('../font/Hack-Italic.ttf');
//     font-style: italic, oblique;
// }

// @font-face {
//     font-family: 'Hack';
//     src: url('../font/Hack-BoldItalic.ttf');
//     font-weight: bold;
//     font-style: italic, oblique;
// }

@font-face {
    font-family: 'Open Sans';
    src: url('../font/OpenSans-Regular.ttf');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 300;
    src: url('../font/OpenSans-SemiBold.ttf');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: bold;
    src: url('../font/OpenSans-Bold.ttf');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 500;
    src: url('../font/OpenSans-ExtraBold.ttf');
}

@font-face {
    font-family: 'Telegrama';
    src: url('../font/telegrama_render.otf');
}

// Our variables
$base-font-family: 'Open Sans', sans-serif;
$mono-font-family: 'Hack', monospace;
$title-font-family: 'Telegrama', monospace;

// Import Bootstrap
@import 
		"../node_modules/bootstrap/scss/functions",
		"../node_modules/bootstrap/scss/variables",
		"variables",
		"../node_modules/bootstrap/scss/mixins",
		"../node_modules/bootstrap/scss/reboot",
		"../node_modules/bootstrap/scss/type",
		"../node_modules/bootstrap/scss/images",
		// "../node_modules/bootstrap/scss/code",
		"../node_modules/bootstrap/scss/grid",
		// "../node_modules/bootstrap/scss/tables",
		// "../node_modules/bootstrap/scss/forms",
		"../node_modules/bootstrap/scss/buttons",
		// "../node_modules/bootstrap/scss/transitions",
		// "../node_modules/bootstrap/scss/dropdown",
		// "../node_modules/bootstrap/scss/button-group",
		// "../node_modules/bootstrap/scss/input-group",
		// "../node_modules/bootstrap/scss/custom-forms",
		"../node_modules/bootstrap/scss/nav",
		"../node_modules/bootstrap/scss/navbar",
		// "../node_modules/bootstrap/scss/card",
		// "../node_modules/bootstrap/scss/breadcrumb",
		// "../node_modules/bootstrap/scss/pagination",
		// "../node_modules/bootstrap/scss/badge",
		// "../node_modules/bootstrap/scss/jumbotron",
		// "../node_modules/bootstrap/scss/alert",
		// "../node_modules/bootstrap/scss/progress",
		// "../node_modules/bootstrap/scss/media",
		// "../node_modules/bootstrap/scss/list-group",
		// "../node_modules/bootstrap/scss/close",
		// "../node_modules/bootstrap/scss/modal",
		// "../node_modules/bootstrap/scss/tooltip",
		// "../node_modules/bootstrap/scss/popover",
		// "../node_modules/bootstrap/scss/carousel",
		"../node_modules/bootstrap/scss/utilities",
		"../node_modules/bootstrap/scss/print"
;

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "fontawesome/fontawesome",
        "fontawesome/fa-brands",
        "fontawesome/fa-solid"
;