// Bootstrap
$enable-rounded:            false;
$body-color:                $black;

$mark-padding:              0;
$mark-bg:                   $yellow;

$dark:          $black;

$theme-colors: map-merge((
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
), $theme-colors);

// Custom
$dark-bg:                   $dark;
