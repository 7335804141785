.mono {
    font-family: $mono-font-family;
}

.hero-header {
    font-family: $title-font-family;
    color: $orange;
    font-size: 1.7em;
}

.name-header {
	font-size: 2em;
	text-align: center;
	background-color: $dark;
	color: $light;
}

.name-header .row {
	min-height: 600px;
	max-height: 90%;
}

.dark {
	background-color: $dark;
}

.pic {
	height: 200px;
}

.contact ul {
    list-style: outside none none;
    margin: 0;
}

/*.contact ul li i {
    color: #003366; 
}*/
.contact ul li span {
    padding-left: 1em;
}

.contact ul li i {
    width: 1.1em;
}

#phone, #email {
	display: none;
}


h3, .h3{
	font-size: 1.2em;
}

h3 .company {
	font-weight:bold;
}

h3 .location {
	font-weight:300;
}

li {
	padding-left: 0.2em;
}
ul {
	padding-left: 1em;
}

mark {
	border-radius: 0.15em;
}

footer {
	color: $light;
	text-align: center;
}

.cc-icon {
	color: $light;
}

footer .row {
	padding: 10px 0px;
}

footer .col {
	text-align: center;
}

@media print{

    .row {
        page-break-inside: avoid;
    }

    .no-page-break {
        page-break-inside: avoid;
    }

    .no-print, .no-print *
    {
        display: none !important;
    }

    *,
    *:before,
    *:after,
    *:first-letter,
    *:first-line {
        background: transparent !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: none;
    }

    /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        margin-top: 0.5em;
        page-break-after: avoid;
    }

    h2 {
        border-bottom: 1px solid gray;
    }
}
